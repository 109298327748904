import { render, staticRenderFns } from "./basic.vue?vue&type=template&id=e83c514e&scoped=true&"
import script from "./basic.vue?vue&type=script&lang=js&"
export * from "./basic.vue?vue&type=script&lang=js&"
import style0 from "./basic.vue?vue&type=style&index=0&id=e83c514e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e83c514e",
  null
  
)

export default component.exports