<template>
  <div class="basicBox">
    <div class="tips">*左侧仅为预览效果，分类的商品全部展示</div>
    <div class="pageName">
      <p>默认分类顺序</p>
      <el-radio-group v-model="goods_classify.is_default" @change="changeIsDefault">
        <el-radio label="1">是</el-radio>
        <el-radio label="0">否</el-radio>
      </el-radio-group>
    </div>
    <template v-if="goods_classify.is_default=='0'">
        <vuedraggable v-model="goods_classify.list"
            v-bind="{
            animation: 200,
            group: 'description',
            disabled: false,
            ghostClass: 'ghost',
            scroll: true
            }"
        >
            <transition-group>
                <div v-for="(item, index) in goods_classify.list" :key="index">
                    <div class="listItem">
                        <div class="left">
                            <div>
                                <span @click="moveClick(0, index, item)" :class="index == 0 ? 'fcccc' : ''">&lt;</span>
                                <span @click="moveClick(1, index, item)" :class="index == goods_classify.list.length - 1?'fcccc':''">></span>
                            </div>
                            <div>第{{ index + 1 }}个分类</div>
                        </div>
                        <div class="mid">
                            <el-input disabled v-model="item.name" placeholder="选择分类"></el-input>
                            <el-button type="primary" @click="chooseClass(index)">选择</el-button>
                        </div>
                        <div class="right">
                            <i v-if="index != 0" class="iconfont icon-guanbi" @click="deleteItem(index)"></i>
                        </div>
                    </div>
                    <div class="pageName" style="margin-bottom: 10px;">
                        <div class="itemMid">
                            <img :src="item.icon || imgH+'orderDefault.png'" alt="icon" class="imgBox"/>
                            <div class="upload" @click="chooseImg(index)">选择图片</div>
                        </div>
                        <div class="itemrright">
                            <div class="tips">分类自定义图标非必填项</div>
                            <div class="clearImg" @click="clearImg(index)">清除图片</div>
                        </div>
                    </div>
                    <p class="tips" style="margin-left:144px;margin-bottom: 10px;">
                        建议尺寸32px*32px
                    </p>
                </div>
            </transition-group>
        </vuedraggable>
        <div @click="addClass" class="addOne">新增一个</div>
    </template>
    <!-- 商品分类弹框 -->
    <el-dialog title="选择分类" :visible.sync="showCategory" width="52%">
      <div>
        <ChooseCategory @getResult="getClass"></ChooseCategory>
      </div>
    </el-dialog>
    <!-- 选图标 -->
    <UploadSource
      v-if="chooseImgFlag"
      @changeStatus="changeStatus"
      :dialogVisible="chooseImgFlag"
      @getSource="getImgRes"
    ></UploadSource>
  </div>
</template>

<script>
import vuedraggable from 'vuedraggable'
import ChooseCategory from '../../addPages/chooseCategory/index'
import { imgUrlHead } from '@/util/config.js'
import UploadSource from '@/components/uploadSource'
export default {
    props: ['goods_classify'],
    components: {
        vuedraggable,
        ChooseCategory,
        UploadSource
    },
    data () {
        return {
            imgH:imgUrlHead,
            showCategory: false,
            index: -1,
            chooseImgFlag:false
        }
    },
    created () {
    },
    methods: {
        changeStatus(val) {
            this.chooseImgFlag = val
        },
        moveClick (status, index, item) {
            if (status == 0) {
                // 向上移
                if (index == 0) {
                this.$message({
                    message: '已经在最前面了~',
                    type: 'error'
                })
                return
                }
                this.goods_classify.list.splice(index, 1)
                this.goods_classify.list.splice(index - 1, 0, item)
            } else {
                // 向下移
                if (index == this.goods_classify.list.length - 1) {
                    this.$message({
                        message: '已经在最后面了~',
                        type: 'error'
                    })
                    return
                }
                this.goods_classify.list.splice(index + 2, 0, item)
                this.goods_classify.list.splice(index, 1)
            }
        },
        addClass () {
            this.goods_classify.list.push({
                name: '选择分类',
                id: -2,
                icon:''
            })
        },
        deleteItem (index) {
            this.goods_classify.list.splice(index, 1)
        },
        chooseClass (index) {
            this.index = index
            this.showCategory = true
        },
        getClass (id, name) {
            this.goods_classify.list[this.index].name = name
            this.goods_classify.list[this.index].id = id
            this.showCategory = false
        },
        // 选择图片
        chooseImg(index) {
            this.index = index
            this.chooseImgFlag = !this.chooseImgFlag
        },
        getImgRes(imglist) {
            this.goods_classify.list[this.index].icon = imglist[0].path
            if (imglist.length > 1) {
                this.$message({
                message: '只能选取一张图，已自动截取第一张',
                type: 'error'
                })
            }
            this.chooseImgFlag = false
        },
        // 清除图片
        clearImg(index) {
            this.goods_classify.list[index].icon = ''
        },
        changeIsDefault(val){
            if(val=='1'){
                this.$parent.getGoodsClassify()
            }
        }
    },
}
</script>

<style lang="less" scoped>
.fcccc {
  color: #ccc;
  border: 1px solid #ccc !important;
  cursor: no-drop !important;
}
.basicBox {
  padding: 30px;
  .tips {
    color: #9a9a9a;
  }
  .pageName {
    display: flex;
    margin-bottom: 20px;
    p {
      width: 120px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    .el-input {
      max-width: 300px;
    }
    .el-radio-group {
      height: 40px;
      display: flex;
      align-items: center;
    }
    .el-checkbox-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .el-checkbox {
        margin: 0 10px 10px 0;
      }
    }
    .el-color-picker {
      width: 200px;
      & /deep/ .el-color-picker__trigger {
        width: 100%;
      }
    }
  }
  .listItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    .left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 5px;
        display: block;
        border: 1px solid #333;
        font-weight: bold;
        cursor: pointer;
        margin-right: 20px;
      }
      span:first-child {
        transform: rotate(90deg);
      }
      span:last-child {
        transform: rotate(90deg);
        margin-top: 10px;
      }
    }
    .mid {
      display: flex;
      align-items: center;
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
    }
  }
  .itemMid {
    position: relative;
    width: 82px;
    height: 82px;
    border: 1px dotted #333;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 144px;
    .imgBox {
      width: 50px;
      height: 50px;
      overflow: hidden;
      margin-bottom: 23px;
    }
    .upload {
      width: 100%;
      line-height: 25px;
      position: absolute;
      bottom: 0;
      left: 0;
      color: #fff;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.5);
      font-size: 14px;
      text-align: center;
    }
  }
  .itemrright{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
       margin-left: 20px;
  }
  .clearImg {
    color: #6d99ff;

    cursor: pointer;
    user-select: none;
  }
  .addOne {
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: #3c76ff;
    border: 1px solid #3c76ff;
    cursor: pointer;
    margin-top: 20px;
  }
}
</style>
